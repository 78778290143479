<template>
    <transition appear enter-active-class="animated fadeIn">
        <div class="has-text-centered">
            <div class="level">
                <div class="title is-1 level-item page-title has-text-centered">Error 404</div>
            </div>

            <div class="box b-shdw-3">
                <span class="title is-1"><i class="fas fa-sad-tear"/></span>
                <p>Looks like the page you are looking for doesn't exist or has moved..</p>
            </div>

            <div class="box b-shdw-3">
                <p>Perhaps one of the links below might help you get where you want?:</p>
                <ul>
                    <li><router-link :to="{name:'home'}">Home</router-link></li>
                    <li><router-link :to="{name:'contactUs'}">Contact Us</router-link></li>
                    <li><router-link :to="{name:'faqs'}">FAQs</router-link></li>
                    <li><router-link :to="{name:'privacyPolicy'}">Privacy policy</router-link></li>
                    <li><router-link :to="{name:'cookiePolicy'}">Cookie policy</router-link></li>
                    <li><router-link :to="{name:'refundPolicy'}">Refund policy</router-link></li>
                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 20px 0 20px 0;
    }
    .fas {
        color: $c2g_orange;
    }
</style>